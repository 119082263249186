@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Montserrat", sans-serif;
    line-height: 1.3;
  }

  table {}

  th,
  td {
    @apply font-medium border-y border-gray-200 border-b-0 ;
  }

  thead {
    @apply bg-neutral-100;
  }

  a {
    @apply text-sky-500 hover:underline;
  }
}

/* Scrollbar CSS*/
::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

/* Scrollbar track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Scrollbar thumb */
::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 5px;
}

/* Scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(143, 143, 143);
}


/* .sidebar{
  width: 18% !important;
} */

/* Calendar
.calendar {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  border: 1px solid #ccc;
  overflow: hidden;
}

.calendar-header,
.calendar-body {
  display: flex;
  flex-wrap: wrap;
}

.calendar-day-header,
.calendar-day {
  flex: 1 0 14.28%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}

.calendar-day-header {
  font-weight: 500;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ccc;
}

.calendar-day {
  min-height: 80px;
  border: 1px solid #f0f0f0;
  position: relative;
}

.calendar-day.today {
  background-color: #e0f7fa;
}

.date-number {
  font-size: 16px;
  font-weight: 800;
}

.empty {
  visibility: hidden;
} */
/* Calendar */
.calendar {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  border: 1px solid #ccc;
  overflow: hidden;
  
}

.calendar-header,
.calendar-body {
  display: flex;
  flex-wrap: wrap;
}

.calendar-day-header,
.calendar-day {
  flex: 0 0 calc(100% / 7); /* Each day takes up 1/7th of the width */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}

.calendar-day-header {
  font-weight: 500;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ccc;
}

.calendar-day {
  min-height: 70px;
  border: 1px solid #f0f0f0;
  position: relative;
}

.calendar-day.today {
  background-color: #e0f7fa;
}

.date-number {
  font-size: 16px;
  font-weight: 800;
}


.empty {
  visibility: hidden;
}
